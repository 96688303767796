import React from "react";

const SocialsLinks = () => {
    return <div className="socials">
    <ul className="socials-nav">
        <li className="d-none"><a className="discord" href="#" target="_blank" rel="noreferrer"></a></li>
        <li className="d-none"><a className="opensea" href="#" target="_blank" rel="noreferrer"></a></li>
        <li><a className="twitter" href="https://twitter.com/MetaKats" target="_blank" rel="noreferrer"></a></li>
    </ul>
</div>
}

export default SocialsLinks;