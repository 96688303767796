import React from "react";
import hoodie from '../assets/images/hoddie-icon@2x.png';
import sweater from '../assets/images/sweater-icon@2x.png'; 
import tee from '../assets/images/tee-icon@2x.png'; 
import merchpromo from '../assets/images/merch-store-home.png'; 

const MerchStore = () => {

  return (
    <>
      <section id="merch-banner">    
        <div className="container">
          <div className="row">
            <div className="col-lg-6 merch-promo">
              <img src={merchpromo} />
            </div>
            <div className="col-lg-5 offset-lg-1 d-flex align-items-center">
              <div className="inner-content">
                <h2 className="h1 mb-4 mb-lg-5"><span>MetaKats</span><br />NFT<br />Merchandise<br />Store</h2>
                <p className="mb-4">The MetaKats NFT merch store will bring the MetaKats into real life. We'll soon be revealing information about our exclusive parnerships with other NFT brands.</p>
                <p className="merch-icons">
                  <img src={hoodie} />
                  <img src={sweater} />
                  <img src={tee} />
                </p>
              </div>              
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MerchStore;
