import React, { useRef, useEffect } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import video from '../assets/videos/metakats_final.mp4';
import placeholder from '../assets/images/placeholder.png';

const UnrealEngine = () => {

  const vid = useRef<HTMLVideoElement>(null);
  gsap.registerPlugin(ScrollTrigger);
  
  useEffect(() => {
    ScrollTrigger.create({
      trigger: "#video",
      onEnter: () => playVideo()
    });
  }, [vid]);

  function playVideo() {
    if (vid) {
      vid.current?.play();
    }
  }

  return (
    <>
      <section id="unreal" className="my-xl-5">
        <div className="container pt-5">

          <div className="w-75 m-auto">
            <div className="pe-lg-5 pe-xl-3">
              <h2 className="h1 display-5 mb-4 mb-xl-5">Unreal Engine</h2>
              <h4 className="mb-2 mb-lg-3">Post launch, for every NFT you are holding, you will receive the 3D files built in Unreal Engine located in the MetaKats Hub. These will be a fully rigged version of your NFT to plug and play into multiple metaverses.</h4>
              <p>We aim to have our MetaKats interoperable across numerous Metaverse platforms, and integrated with full animation into as many games as possible.</p>
            </div>

          </div>
        </div>
      </section>
      <section id="video" className="mb-md-5 pb-lg-5">
        <div className="container px-0 py-5">
          <video preload="auto" ref={vid} className="d-md-none mx-auto" poster={placeholder} playsInline muted autoPlay>
            <source src={video} type="video/mp4" ></source>
          </video>
          <video preload="auto" ref={vid} className="d-md-block d-none mx-auto" controls poster={placeholder} playsInline muted>
            <source src={video} type="video/mp4" ></source>
          </video>
        </div>
      </section>
    </>
    );

}

export default UnrealEngine;