import React from "react";
import Accordion from 'react-bootstrap/Accordion';

const FAQs = () => {
  return (
    <section id="faqs" className="bg-dark text-light pt-5 py-md-5">
      <div className="container py-lg-5">
        <div className="row py-lg-3">
          <div className="col-lg-10 mx-md-auto faq-wrapper">
            <h2 className="h1 display-5">FAQs</h2>
            <h4 className="mb-4 mb-lg-5">A list of commonly asked questions</h4>
            <Accordion className="accordion" id="faqAccordion">
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">When will MetaKats launch?</Accordion.Header>
                <Accordion.Body>
                  <p>TBD</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="accordion-item">
                <Accordion.Header className="accordion-header">How many MetaKats will there be?</Accordion.Header>
                <Accordion.Body>
                  <p>There will be a total of 5555 unique MetaKats, with combinations from over 250 unique traits.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="accordion-item">
                <Accordion.Header className="accordion-header">What will the mint price be?</Accordion.Header>
                <Accordion.Body>
                  <p>The mint price to own a MetaKat will be released closer to the launch date. As a team we have decided to come in with a lower starting price than a lot of new projects.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="accordion-item">
                <Accordion.Header className="accordion-header">How many MetaKats can I mint?</Accordion.Header>
                <Accordion.Body>
                  <p>The MetaKats community members are allowed to mint up to a maximum of 3 NFTs per wallet.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="accordion-item">
                <Accordion.Header className="accordion-header">What network will MetaKats be on?</Accordion.Header>
                <Accordion.Body>
                  <p>MetaKats will launch on Ethereum blockchain.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="accordion-item">
                <Accordion.Header className="accordion-header">Wen reveal?</Accordion.Header>
                <Accordion.Body>
                  <p>The reveal for the MetaKats will occur within 24 hours of selling out.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="accordion-item">
                <Accordion.Header className="accordion-header">How can I join the discord?</Accordion.Header>
                <Accordion.Body>
                  <p>Our Discord link will be published on our official Twitter when we go live with Discord.</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </div>
        </div>
      </div>
    </section>
  )

}

export default FAQs;