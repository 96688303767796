import React from "react";
import shrek from '../assets/images/social-kat.jpg';
import SocialsLinks from './SocialsLinks';

const SocialsBanner = () => {

  return (
    <>
      <div id="about"></div>
      <section id="socials-banner">    
        <div className="container">
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <div className="socials-banner-content">
                <h2 className="h1 mb-4 mb-lg-5">Easily led, not so easily tamed.</h2>
                <h4 className="mb-2 mb-lg-3">MetaKats are full of mischief, agile, love to dress up, and will eat you out of house and home.</h4>
                <p className="mb-4 mb-lg-5">But they're fluffy and cuddly so we love em.</p>
                <SocialsLinks />
              </div>              
              <img className="d-lg-none w-100" src={shrek} alt="Shrek MetaKat" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SocialsBanner;
