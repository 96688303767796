import React, { useEffect, useRef, useState } from "react";
import TinySlider, { TinySliderSettings } from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';

const Roadmap = () => {
    const ts = useRef<TinySlider>(null);

    const settings: TinySliderSettings = {

        "loop": false,
        "swipeAngle": false,
        "mouseDrag": true,
        "speed": 400,
        "controls": false,
        "navAsThumbnails": true,
        "navContainer": '.rmnav-container',
        "slideBy": 1,
        "responsive": {
            "350": {
                "items": 1.2
            },
            "768": {
                "items": 2
            },
            "992": {
                "items": 3
            }
        },
    };

    return <div>
        <section id="roadmap" className="my-md-5">
            <div className="container mb-md-4">
                <div className="row align-items-center py-lg-4">
                    <div className="col-md-6 p-5 p-lg-0">
                        <h2 className="h1 display-5 mb-md-4">Road Map</h2>
                    </div>
                    <div className="col-md-6">
                        <ol className="rmnav-container">
                            <li></li>
                            <li></li>
                            <li></li>
                        </ol>
                    </div>
                </div>
            </div>
            <div id="roadmap-cards" className="card-slider container px-md-2">
                <TinySlider settings={settings} ref={ts}>
                    <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="rm-title">MetaKats Hub</h5>
                                <p>MetaKats Hub is something we are very excited about building. This will be a dashboard where you will be able to sign in to view all of your MetaKats and fully customize them. Whether that is changing the background, equip a new shirt, adding a gun, face masks, or even audio; you’ll be able to do it all.</p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="rm-title">Unreal Engine</h5>
                                <p>For every NFT you are holding, you will receive the 3D files in the MetaKats Hub built in Unreal Engine, fully rigged version of their NFT to plug and play into multiple metaverses. We aim to have our MetaKats interoperable across numerous Metaverse platforms, and integrated with full animation into as many games as possible.</p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="rm-title">Every Trait Matters</h5>
                                <p>One of our most prioritized goals is to continuously give back and provide value to the MetaKat’s community. The team are already working on add-on equipables for the MetaKats community. These will be unique one-off drops based on traits / partners that can be equipped to your NFT’s anytime in the MetaKats Hub.</p>
                            </div>
                        </div>
                    </div>

                    <div className="item"></div>
                </TinySlider>
            </div>
        </section>
    </div >
}

export default Roadmap;