import React from "react";

import image1 from '../assets/images/discord/discord-1-3.png';
import image2 from '../assets/images/discord/row-1-block-2.png';
import image3 from '../assets/images/discord/discord-3.png';
import image4 from '../assets/images/discord/discord-4.png';
import image5 from '../assets/images/discord/row-2-block-2.png';
import image6 from '../assets/images/discord/discord-6.jpeg';
import image7 from '../assets/images/discord/discord-7.png';
import image8 from '../assets/images/discord/discord-8.jpeg';
import image9 from '../assets/images/discord/discord-9-2.png';


const JoinDiscord = () => {
  return <div>
    <section id="join-discord" className="py-xl-5">
      <div className="position-relative d-lg-flex flex-row align-items-center discord-wrapper">
        <div className='nft-collection'>
          <img src={image1} />
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
          <img src={image5} />
          <img src={image6} />
          <img src={image7} />
          <img src={image8} />
          <img src={image9} className="d-none d-md-block" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="join-discord-content pe-lg-5 pe-xl-3">
                <h2 className="h1 display-5 mb-4 mb-xl-5">Join us on discord</h2>
                <h4 className="mb-4 mb-xl-5">Once you’ve secured your own MetaKat, you can keep upto date with these little rascals on Discord.</h4>
                <a href="#" className="btn btn-primary mt-xl-4">Coming Soon</a>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>

      </div>
    </section>
  </div>
}

export default JoinDiscord;