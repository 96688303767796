import React from "react";

const HomeHero = () => {
    return <section id="home" className="home-hero px-lg-4 p-xl-4">
      <div className="home-hero-content py-5 pe-5 pe-lg-0">
          <h1 className="hero-title display-2 mb-md-4 mb-xxl-5">What are MetaKats?</h1>
          <h4 className="mt-4 mb-2 mb-md-3">MetaKats are some of the most fierce animals around (in their own minds).</h4>
          <p className="mb-4 mb-md-5">The genesis collection will contain 5,555 unique 3D avatars which will become fully customizable, and rigged to plug and play into multiple metaverses.</p>
          <a href="#" className="btn btn-dark">Coming Soon</a>
      </div>
    </section>
}

export default HomeHero;