import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from './pages/Home';
import './assets/sass/style.scss';

function App() {

  var routerBaseName = process.env.REACT_APP_ROUTER_BASE;

  return (
    <div className="App">
      <Router basename={routerBaseName}>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div >
  );
}

export default App;