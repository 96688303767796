import React from 'react';
import Navigation from '../components/Navigation';
import HomeHero from '../components/HomeHero';
import SocialsBanner from '../components/SocialsBanner';
import MerchStore from '../components/MerchStore';
import Unreal from '../components/Unreal';
import Video from '../components/Video';
import JoinDiscord from '../components/JoinDiscord';
import TheTeam from '../components/TheTeam';
import Roadmap from '../components/Roadmap';
import FAQs from '../components/FAQs';
import Footer from '../components/Footer';

const Home = () => {

    return <div>
        <Navigation />
        <HomeHero />
        <SocialsBanner />
        <Unreal />
        <Video />
        <JoinDiscord />
        <MerchStore />
        <TheTeam />
        <FAQs />
        <Roadmap />
        <Footer />
    </div>;
}

export default Home;