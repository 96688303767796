import logo from '../assets/images/mk-icon-2.svg';
import logo2 from '../assets/images/mk-icon-3.svg';
import logotype from '../assets/images/meta-kats-logo-type.svg';
import SocialsLinks from './SocialsLinks';
import { Container, Nav, Navbar, Collapse } from 'react-bootstrap';

const Navigation = () => {

    return (
        <Navbar expand="lg" className='navbar w-100 navbar-expand-lg navbar-light py-3 px-md-4' sticky='top' collapseOnSelect={true}>
            <div className="container-fluid px-0 px-lg-3">
                <div className='brand'>
                    <Navbar.Brand href="#home" className="navbar-brand d-block d-md-none">
                        <img className="brand-img d-inline-flex me-4" src={logo2} alt="MetaKats" />
                    </Navbar.Brand>
                    <Navbar.Brand href="#home" className="navbar-brand d-none d-md-block">
                        <img className="brand-img d-inline-flex me-4" src={logo} alt="MetaKats" /><img className="brand-name d-inline-flex" src={logotype} alt="MetaKats" />
                    </Navbar.Brand>
                </div>

                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav className="navbar-nav px-4 px-md-0">
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#join-discord">Discord</Nav.Link>
                        <Nav.Link href="#theteam">Team</Nav.Link>
                        <Nav.Link href="#faqs">FAQs</Nav.Link>
                        <Nav.Link href="#roadmap">Roadmap</Nav.Link>
                        <Nav.Link href="https://docs.metakats.io/" target="_blank">Docs</Nav.Link>
                        <Nav.Link href="https://docs.metakats.io/mkpaper/metakats-hub" target="_blank">MetaKats Hub - Coming soon</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <SocialsLinks />
                <div className='navbar-toggler-container'>
                    <Navbar.Toggle aria-controls="navbarSupportedContent" />
                </div>
            </div>
        </Navbar>
    )

}

export default Navigation;