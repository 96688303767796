import React from "react";
import icon1 from '../assets/images/teams/mk-icon-yellow@2x.png';
import icon2 from '../assets/images/teams/mk-icon-red@2x.png';
import icon3 from '../assets/images/teams/mk-icon-grey@2x.png';
import icon4 from '../assets/images/teams/mk-icon-light-grey@2x.png';
import icon5 from '../assets/images/teams/mk-icon-dark-grey@2x.png';

import avatar1 from '../assets/images/teams/eric@2x.png';
import avatar2 from '../assets/images/teams/brandon@2x.png';
import avatar3 from '../assets/images/teams/adam@2x.png';
import avatar4 from '../assets/images/teams/esh-avatar@2x.png';
import avatar5 from '../assets/images/teams/chris-avatar@2x.png';

import insta from '../assets/images/insta-black.svg';
import twitter from '../assets/images/twitter-icon-black.svg';
import linkedin from '../assets/images/linkedin-black.svg';
import instawhite from '../assets/images/insta.svg';
import twitterwhite from '../assets/images/twitter-icon-2.svg';
import linkedinwhite from '../assets/images/linkedin.svg';

const ericContent = () => {
  return (
    <>
      <h3 className="h1 pb-2">Eric</h3>
      <p className="mb-4">Eric is a developer and the Founder of <a href="https://manifutures.com/" target="_blank">Manifest Futures</a>. He leads a team of very talented developers with vast experience doing smart contracts for NFT projects in the market.</p>
      <p className="mb-2 socials-nav">
        <a href="https://twitter.com/HurricaneSoto"><img className="social-icon" src={twitter} /></a>
        <a href="https://www.linkedin.com/in/eric-soto/"><img className="social-icon" src={linkedin} /></a>
      </p>
    </>
  )
}

const brandonContent = () => {
  return (
    <>
      <h3 className="h1 pb-2">Brandon</h3>
      <p className="mb-2">Brandon's role is to identify and establish strategic partnerships, helping to bridge the gap between MetaKats and other amazing projects, brands, and companies in Web2 and Web3.</p>
      <p className="mb-2">Over the years, Brandon has worked in Business Development within enterprise technology before expanding into Web3. </p>
      <p className="mb-4">Brandon and his team's experience will help us in cultivating diverse, robust, and innovative partnerships across Metaverse communities.</p>
      <p className="mb-2 socials-nav">
        <a href="https://twitter.com/TheDazzleNovak"><img className="social-icon" src={twitterwhite} /></a>
      </p>
    </>
  )
}

const adamContent = () => {
  return (
    <>
      <h3 className="h1 pb-3">Adam</h3>
      <p className="mb-4">Adam has been in the crypto space since 2017 and built up quite a large network across his social media accounts, and recently shifted his focus towards NFTs and the Metaverse. Adam will be responsible for content creation, managing marketing campaigns, exploring partnership opportunities, and working with companies and influencers to expand the community.</p>
      <p className="mb-2 socials-nav">
        <a href="https://www.twitter.com/adamHODL"><img className="social-icon" src={twitterwhite} /></a>
      </p>
    </>
  )
}

const eshContent = () => {
  return (
    <>
      <h3 className="h1 pb-3">Esh</h3>
      <p className="mb-2">Esh is a crypto &amp; NFT enthusiast and co founder of the brand to blockchain company, <a href="https://gluu.agency" target="_blank">GLUU Agency</a>.</p>
      <p className="mb-4">Esh has worked on projects with Rakuten, ECR, Love2Shop and many more. Esh will assist the design and development team and with his strong client-side business development experience in sales and marketing in both B2C and B2B business he aims to facilitate the introduction of global brands to MetaKats.</p>
      <p className="mb-2 socials-nav">
        <a href="https://twitter.com/EshGluu"><img className="social-icon" src={twitter} /></a>
        <a href="https://www.instagram.com/ESH.GLUU/"><img className="social-icon" src={insta} /></a>
      </p>
    </>
  )
}
const chrisContent = () => {
  return (
    <>
      <h3 className="h1 pb-3">Chris</h3>
      <p className="mb-2">Chris is an enthusiastic artist who has spent over 20 years in the industry as a Senior Art Director and specialising in Game VFX.</p>
      <p className="mb-2">Chris loves bringing designs to life and has worked with many prestigious companies such as Ferrari, Sony and Lamborghini.</p>
      <p className="mb-2">Chris is a big asset to this project, and we are extremely excited to be working with him.</p>
      <p className="mb-2">View all his work at <a href="https://www.chrisrabet.com/" target="_blank">https://www.chrisrabet.com/</a></p>
    </>
  )
}

const TheTeam = () => {

  return (
    <div>
      <section id="theteam" className="py-xl-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="inner-content">
                <h2 className="h1 display-5 mb-4 mb-lg-4">The Team</h2>
                <h4>Developed by a dedicated team with a real vision to explode into multiple metaverses. Remember a Metakat isn’t just for gaming, it's for life.</h4>
              </div>

            </div>

          </div>


        </div>
        <div className="container pb-5">
          <div className="row">
            <div className="col">
              <div className="mx-3 mx-md-0">
                <div className="d-md-flex flex-columm flex-md-row-reverse team-card justify-md-center align-items-md-center team-light-grey">
                  <div className="team-content">
                    {chrisContent()}
                  </div>

                  <div className="team-avatar-wrapper align-self-md-end">
                    <img src={icon5} alt="MetaKats icon" className="team-icon" />
                    <img src={avatar5} alt="Eric from MetaKats as a MetaKats avatar" className="team-avatar" />
                  </div>
                </div>
                <div className="d-md-flex flex-columm flex-md-row-reverse team-card justify-md-center align-items-md-center team-grey">
                  <div className="team-content">
                    {adamContent()}
                  </div>

                  <div className="team-avatar-wrapper align-self-md-end">
                    <img src={icon3} alt="MetaKats icon" className="team-icon" />
                    <img src={avatar3} alt="Eric from MetaKats as a MetaKats avatar" className="team-avatar" />
                  </div>
                </div>
                <div className="d-md-flex flex-columm flex-md-row-reverse team-card justify-md-center align-items-md-center team-light-grey">
                  <div className="team-content">
                    {eshContent()}
                  </div>

                  <div className="team-avatar-wrapper align-self-md-end">
                    <img src={icon4} alt="MetaKats icon" className="team-icon" />
                    <img src={avatar4} alt="Eric from MetaKats as a MetaKats avatar" className="team-avatar" />
                  </div>
                </div>
                <div className="d-md-flex flex-columm flex-md-row-reverse team-card justify-md-center align-items-md-center team-yellow">
                  <div className="team-content">
                    {ericContent()}
                  </div>

                  <div className="team-avatar-wrapper align-self-md-end">
                    <img src={icon1} alt="MetaKats icon" className="team-icon" />
                    <img src={avatar1} alt="Eric from MetaKats as a MetaKats avatar" className="team-avatar" />
                  </div>
                </div>
                <div className="d-md-flex flex-columm flex-md-row-reverse team-card justify-md-center align-items-md-center team-red">
                  <div className="team-content">
                    {brandonContent()}
                  </div>

                  <div className="team-avatar-wrapper align-self-md-end">
                    <img src={icon2} alt="MetaKats icon" className="team-icon" />
                    <img src={avatar2} alt="Eric from MetaKats as a MetaKats avatar" className="team-avatar" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default TheTeam;