import React from "react";
import logo from '../assets/images/mk-icon-3.svg';
import logotype from '../assets/images/meta-kats-logo-type.svg';
import footergraphic from '../assets/images/footer-graphic.png';
import SocialsLinks from './SocialsLinks';

const Footer = () => {
    return <footer id="footer">
        <div className="container-fluid">
            <div className="brand mx-auto">
                <img className="brand-img d-flex mx-auto mb-4" src={logo} alt="MetaKats" />
                <img className="brand-name d-flex mx-auto mb-4" src={logotype} alt="MetaKats" />
            </div>
            <SocialsLinks />
            <img className="footer-graphic" src={footergraphic} alt="MetaKats" />
        </div>
    </footer>
}

export default Footer;